import React from "react";
import "../styles/Footer.css";
import instagram from "../assets/instagram.svg";
import Linked from "../assets/Linked.svg";
import Github from "../assets/Github.svg";

function Footer() {
  return (
    <div className="footer ">
      <div className="footer-main">
        <div className="alamat-footer">
          <h1 className="Foot-nama text-3xl">Arrafi Fakhri Hadi</h1>
          <p>Web Developer - Data Scientist</p>
        </div>
        <div className="account-footer">
          <h3 className="text-xl mt-5 ml-1">Contact me</h3>
          <div className="account-icons ml-0 gap-2">
            <div className="account">
              <a
                href="https://www.instagram.com/arrafifakhri/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  className="logo-iconFoot"
                />
              </a>
            </div>
            <div className="account">
              <a
                href="https://www.linkedin.com/in/arrafi-fakhri-hadi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Linked} alt="LinkedIn" className="logo-iconFoot" />
              </a>
            </div>
            <div className="account">
              <a
                href="https://github.com/arrafi1212"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Github} alt="Github" className="logo-iconFoot" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
